import Container from 'react-bootstrap/Container';
import { useRef } from 'react';
import { MemoryRouter, Routes, Route} from 'react-router-dom';
import io from 'socket.io-client';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home';
import Answer_Round from './pages/answer_round';
import Waiting_Room from './pages/waiting_room';
import Vote_Round from './pages/vote_round';
import Score_Round from './pages/score_round';
import { v4 as uuidv4 } from 'uuid';

const uid = uuidv4();

//const socket = io.connect('http://localhost:4000',
const socket = io.connect('https://server.hotreels.party/',
{
  withCredentials: true,
  extraHeaders: {
    uid: uid //setting UID here so it's known on first connection. setting on server is hard to track on first connection
  }
}
);



function App() {
  const movie = useRef({});
  const answers = useRef([]);
  const username = useRef('');
  const roomcode = useRef('');
  //const [scoreboard, setScoreboard] = useState({});

  return (
    <Container fluid className="App">
      <MemoryRouter>
          <Header />
          <Routes>
            <Route path='/' element={<Home 
              username={username} 
              roomcode={roomcode} 
              socket={socket}
              movie={movie}
              answers={answers}
            //  setScoreboard = {setScoreboard}
              />} />

            <Route path='/waiting_room'element={<Waiting_Room 
            username={username}
            roomcode = {roomcode}
            movie= {movie}
            socket={socket} />}/>

          <Route path='/answer_round'element={<Answer_Round 
            username={username}
            roomcode = {roomcode}
            movie = {movie}
            answers = {answers}
            socket={socket} />}/>


          <Route path='/vote_round'element={<Vote_Round 
            username={username}
            roomcode = {roomcode}
            movie = {movie}
            answers = {answers}
           // setScoreboard = {setScoreboard}
            socket={socket}
            />}/>

          <Route path='/score_round'element={<Score_Round 
          //  scoreboard = {scoreboard}
            roomcode = {roomcode}
            movie = {movie}
            socket={socket}
            answers = {answers} />}/> 


          </Routes>
          <Footer />
        </MemoryRouter>
  </Container>
  );
}

export default App;

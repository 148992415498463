import Body from '../../components/Body';
import { useNavigate } from 'react-router-dom'; 
import { useState, useEffect, useRef } from 'react';
import VoteButtons from '../../components/VoteButtons';
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';

const Vote_Round = ({username,roomcode,movie, answers, socket}) => {
    const [showVotes, setShowVotes] = useState(false);
    const navigate = useNavigate();
    const [blockScroll, allowScroll] = useScrollBlock();
    blockScroll();
    console.log(answers.current);
    useEffect(() => {
        //voting round ended
        socket.on('score_round_started', () => {
            navigate('/score_round', { replace: true });
        });
        return () => socket.off('score_round_started');
        }, [socket]);


    useEffect(() => {
        //show choices on phones
        socket.on('show_answers_phone', () => {
            setShowVotes(true);
        });
        return () => socket.off('score_round_started');
        }, [socket]);






    const onClick = (ev) => {
        ev.preventDefault();
        let votedFor = ev.target.value;
        socket.emit('vote_in', { username, roomcode, votedFor});
        setShowVotes(false);
    
    }

    return (
//NEED TO CHANGE IT SO IT ADDS USER TO A STACK OF USERS <CountdownTimer seconds  = {20} />
    <Body >
            


            
            {showVotes && 
            <div>
                <h1> Vote on best parody title for: <div style = {{ color: '#0d6efd', display: 'inline'}}>{movie.current.title}</div></h1>
                <br></br>
                <VoteButtons answers = {answers.current} username = {username} onClick = {onClick} socket = {socket} />
            </div>
            }

            {!showVotes && <TvAttention/>}
    </Body>

    );
};

export default Vote_Round;

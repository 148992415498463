
import Container from 'react-bootstrap/Container';
import { Steam } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';

function Footer() {
  return (
 
      <Container>
       <Stack gap={2} className="fixed-bottom">
       <Col className="d-flex mx-auto"> 
          <Button  variant="secondary" size="lg" href = "https://store.steampowered.com/app/3147120/Hot_Reels/" target="_blank" rel="noreferrer">
            <Steam /> Get Hot Reels
          </Button>
        </Col>
        <br></br>
        </Stack>
      </Container>
 
  );
}

export default Footer;
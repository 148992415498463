import Body from '../../components/Body';
//import ScoreBoard from '../../components/ScoreBoard';
import { useNavigate } from 'react-router-dom'; 
import { useEffect} from 'react';
import useScrollBlock from '../../components/useScrollBlock';
import TvAttention from '../../components/TvAttention';

const Score_Round = ({roomcode, movie, socket, answers}) => {
    const navigate = useNavigate();
    const [blockScroll, allowScroll] = useScrollBlock();
    blockScroll();

    // useEffect(() => {
        //voting round ended
        // socket.on('new_round', () => {
        //     socket.emit('start_answer_round', { roomcode: roomcode.current });
        // });
        // return () => socket.off('new_round');
        // }, [socket]);


        useEffect(() => {
            socket.on('answer_round_started', (randMovie) => {
                movie.current = randMovie;
                navigate('/answer_round', { replace: true });
            });
            return () => socket.off('answer_round_started');
            
            }, [socket]);

        useEffect(() => {
            //voting round started
            socket.on('vote_round_started', (parodies) => {

                answers.current = parodies;
                navigate('/vote_round', { replace: true });
            });
            return () => socket.off('vote_round_started');
            }, [socket]);


    return (
        //NEED TO CHANGE IT SO IT ADDS USER TO A STACK OF USERS <ScoreBoard scoreboard={scoreboard}/>  
            <Body >
                <TvAttention/>
            </Body>
        
            );

};

export default Score_Round;
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../hrlogo50.png';

function Header() {
  return (
    <Navbar bg="light" sticky="top" className="Header">
      <Container>
        <Navbar.Brand><img src={logo} /></Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default Header;
import Container from 'react-bootstrap/Container';
import TvEyes from '../TV_eyes.svg';

 function TvAttention() {
  return (
        <div>
            <h1> Look at the main game screen</h1>
            <br></br>
            <div class="d-flex justify-content-center">
                <img src={TvEyes} width='60%' style={{ alignSelf: 'center' }} />
            </div>
        </div>
  );
}

export default TvAttention;